import { RouteComponentProps, useLocation } from '@reach/router'
import qs from 'query-string'
import React, { FC, useEffect } from 'react'
import {
  LoginViewScreen,
  setCurrentLoginViewScreen,
  useUserStore
} from '../../stores/userStore'
import { ConfirmScreen } from './ConfirmScreen'
import { LoginScreen } from './LoginScreen'
import './LoginView.scss'
import { SendEmailScreen } from './SendEmailScreen'
import { SetNewPasswordScreen } from './SetNewPasswordScreen'

/**
 * Interface für die Login View Properties.
 */
interface Props extends RouteComponentProps {
  /**
   * Gibt an ob der Login Screen gezeigt werden soll.
   * Falls true kommt der normale Login Screen,
   * Falls false wird direkt die Passwort vergessen Seite angezeigt.
   */
  showLogin?: boolean
  /**
   * Gibt an ob der Websocket noch beim connect ist.
   */
  connectingSocket: boolean
}

export const LoginView: FC<Props> = ({
  showLogin = true,
  connectingSocket
}) => {
  useResetTokenRedirect()

  const { currentLoginViewScreen } = useUserStore()

  useEffect(() => {
    if (currentLoginViewScreen === LoginViewScreen.login) {
      setCurrentLoginViewScreen(
        showLogin ? LoginViewScreen.login : LoginViewScreen.sendEMail
      )
    }
  }, [currentLoginViewScreen, showLogin])

  return (
    <div className="login-view">
      {showLogin ? <LoginScreen connectingSocket={connectingSocket} /> : null}
      <SendEmailScreen showBackToLogin={showLogin} />
      <SetNewPasswordScreen />
      <ConfirmScreen />
    </div>
  )
}

function useResetTokenRedirect (): void {
  const location = useLocation()

  useEffect(() => {
    const queryParams = qs.parse(location.search)
    const passwordResetToken = queryParams.passwordResetToken
    if (typeof passwordResetToken !== 'string') {
      return
    }
    setCurrentLoginViewScreen(LoginViewScreen.setNewPassword)
  }, [location.search])
}
