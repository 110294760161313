import { mdiAccountEdit, mdiAccountKey, mdiAccountMultiple, mdiBeakerCheckOutline, mdiDelete, mdiEyeCheck, mdiEyeOff } from '@mdi/js'
import { ApiTaskDataresourceAssetCoAuthor } from 'nomosportal_interfaces/ApiTaskInterfaces'
import { changeCoAuthorReleaseCustom, setTaskDiscardPending } from 'sfportal_stores_extensions/productDetailStoreExtension'
import React, { Dispatch, ReactElement, SetStateAction, useCallback, useEffect, useMemo, useState } from 'react'
import { AlertManager, useAlert } from 'react-alert'
import { useTranslation } from 'react-i18next'
import { MdiIcon } from 'sfportal_components_generic/MdiIcon'
import { TabularData } from 'sfportal_components_generic/TabularData'
import { IListItemTaskAssetUtils } from 'sfportal_components_listitem_utils_interfaces/IListItemTaskAssetUtils'
import { MenuItem } from 'sfportal_components_menu/MenuItem'
import { ApiJwtAuthenticationDetails, ApiTask, ApiTaskDataResourceAsset, EMK } from 'sfportal_services_api/apiSchemas'
import { RequestStatus } from 'sfportal_services_api/generic/types'
import { claimTasks, completeTaskWithComment, useProductDetailStore } from 'sfportal_stores/productDetailStore'
import { useUserStore } from 'sfportal_stores/userStore'
import { noop } from 'sfportal_utils/function'
import { createSignal, useSignal } from 'sfportal_utils/signals'
import { ConfirmModal } from '../../../../../components/Modals/ConfirmModal'
import { apiDeleteAssetLock, apiHasLock } from '../../../../../services/api/filesApiService'
import { apiPostTasks } from '../../../../../services/api/tasksApiService'
import { Response } from 'superagent'

/**
 * Signal, das zum Öffnen/Schließen des Dialogs "Dokument ohne Kommentar an den
 * Verlag abgeben?" (Messbecher-Funktion) verwendet wird.
 */
const toggleCompleteTaskWithoutCommentModalSignal = createSignal<{
  visible: boolean
  taskId: ApiTask['id']
}>()

/**
 * Prüft ob der additional dropdown render überhaupt gerendert werden soll.
 *
 * @param dataResource Die DataResource zum Task.
 * @param currentUser Der aktuell angemeldete User.
 * @return true, falls die additional dropdown render gerendert werden können. false, falls nicht.
 */
function additionalDropDownRenderRequirement (dataResource: ApiTaskDataResourceAsset, currentUser: ApiJwtAuthenticationDetails | null): boolean {
  // Der Render ist nur erlaubt, wenn der User Autor des Assets ist und die das Property für die Autorenfreigabe im Workflow des BO gesetzt wurde.
  return currentUser === null
    ? false
    : dataResource.authors.map(value => value.id).includes(currentUser?.id) && dataResource.assetPrivileges.canUserGrantReleaseForCoAuthor
}

/**
 * Liefert die Icons für die Mitautoren Symbolic abhängig vom Bearbeiter und angemeldeten User.
 *
 * @param currentUser Der angemeldete User.
 * @param taskAssignee Der User, der dem Task zugeordnet ist.
 * @return Array mit den Icons abhängig von der User Konstellation von assignee und angemeldetem User.
 */
function getMultipleAuthorsIcons (
  currentUser: ApiJwtAuthenticationDetails | null,
  taskAssignee: string | null
): Array<ReactElement<typeof MdiIcon>> {
  return [!taskAssignee
    ? <MdiIcon key={1} path={mdiAccountMultiple}/>
    : <MdiIcon key={1} path={taskAssignee === currentUser?.username ? mdiAccountEdit : mdiAccountKey}/>]
}

/**
 * Liefert ein Array an Elemente, welche bei den Tasks als Status Icons gerendert werden knnen.
 *
 * @param dataResource Die DataResource zum Task.
 * @param currentUser Der aktuell angemeldete User.
 * @param taskAssignee Der dem Task zugeordnete User.
 * @return Ein Array an Status Elementen.
 */
function getStatusIcons (
  dataResource: ApiTaskDataResourceAsset,
  currentUser: ApiJwtAuthenticationDetails | null,
  taskAssignee: string | null
): Array<ReactElement<typeof MdiIcon>> {
  const dataResourceCoAuthor = dataResource as ApiTaskDataresourceAssetCoAuthor
  return [
    <MdiIcon key={0} path={dataResourceCoAuthor.hasGrantedReleaseForCoAuthor ? mdiEyeCheck : mdiEyeOff}/>,
    ...dataResource.authors.length > 1 ? getMultipleAuthorsIcons(currentUser, taskAssignee) : []
  ]
}

/**
 * Komponente um zusätzliche Felder im Dropdown Menü von Tasks mit Assets zu rendern.
 *
 * @param task Der Task.
 * @constructor Function Komponente.
 */
const AdditionalDropDownRender: IListItemTaskAssetUtils['AdditionalDropDownRender'] = ({
  task,
  dataresourceLocked = false
}) => {
  const [updatedTask, setUpdatedTask] = useState<ApiTask | undefined>(undefined)

  const { product } = useProductDetailStore()
  const id = product?.id ?? null
  if (id !== null) {
    apiPostTasks(id, [task.id], () => product?.id ?? null).then(result => {
      setUpdatedTask((result as Response).body[0] as ApiTask)
    })
  }

  // Das Asset muss in eine Co Author asset geparst werden um auf die Erlaubnis der Mitarbeitarbeiterfreigabe setzen zu können.
  const dataResourceCoAuthor = useMemo(() => updatedTask?.dataResource as ApiTaskDataresourceAssetCoAuthor | undefined, [updatedTask])

  // Status der angibt ob der Mitautorenfreigabe-Button aktiv ist.
  const [enabled, setEnabled] = useState(true)

  const { taskActionStatus } = useProductDetailStore()
  const { currentUser } = useUserStore()

  // Gibt an ob die Weitergabe des Tasks ohne Bearbeitung gerade ausgeführt wird.
  const taskActionIsPending = useMemo(
    () => taskActionStatus === RequestStatus.pending,
    [taskActionStatus]
  )

  // Prüft ob der Task überhaupt abgeschlossen werden kann.
  const checkCompletable = useMemo(() => {
    return updatedTask !== undefined && !(currentUser === null ||
      (updatedTask.assignee && updatedTask.assignee !== currentUser.username) ||
      (!updatedTask.assignee && !updatedTask.taskPrivileges.canUserClaim))
  }, [currentUser, updatedTask])

  // Handelt den Klick auf den Mitautorenfreigabe Button.
  const handleChangeCoAuthorReleaseOnClick = useCallback(async () => {
    if (dataResourceCoAuthor === undefined) {
      return
    }
    // Der Button soll disabled werden, solange der Statechange auf der Datenbank läuft.
    setEnabled(false)
    await changeCoAuthorReleaseCustom(dataResourceCoAuthor.id)
    setEnabled(true)
  }, [dataResourceCoAuthor])

  function handleCompleteTask (): void {
    toggleCompleteTaskWithoutCommentModalSignal.send({
      visible: true,
      taskId: updatedTask?.id ?? ''
    })
  }

  return <>
    {dataResourceCoAuthor ? <MenuItem
      label={dataResourceCoAuthor.hasGrantedReleaseForCoAuthor
        ? 'Mitautorenfreigabe entziehen'
        : 'Mitautorenfreigabe erteilen'}
      icon={dataResourceCoAuthor.hasGrantedReleaseForCoAuthor
        ? mdiEyeOff
        : mdiEyeCheck}
      enabled={enabled}
      action={handleChangeCoAuthorReleaseOnClick} /> : null}
    <MenuItem
      label={'Keine Bearbeitung erforderlich, aktuell'}
      icon={mdiBeakerCheckOutline}
      enabled={checkCompletable && !taskActionIsPending && !dataresourceLocked}
      action={handleCompleteTask} />
    <MenuItem
      label={'Heruntergeladene Datei verwerfen'}
      icon={mdiDelete}
      enabled={checkCompletable && !taskActionIsPending && dataresourceLocked}
      action={() => setTaskDiscardPending(task, true)} />
  </>
}

const AdditionalRootRender: IListItemTaskAssetUtils['AdditionalDropDownRender'] = ({ task }) => {
  const { currentUser } = useUserStore()
  const { t } = useTranslation()
  const alert = useAlert()

  const [visibleDiscardModal, setVisibleDiscardModal] = useState<boolean>(false)

  useEffect(() => {
    setVisibleDiscardModal(task.discardPending ?? false)
  }, [task.discardPending])

  const checkCompletable = useMemo(() => {
    if (currentUser === null) return false

    // Aufgabe ist bereits einem anderen Nutzer zugewiesen.
    if (task.assignee && task.assignee !== currentUser.username) {
      return false
    }
    // Aufgabe ist nicht zugewiesen, Nutzer hat aber keine Rechte,

    // die Aufgabe anzunehmen.
    return !(!task.assignee && !task.taskPrivileges.canUserClaim)
  }, [currentUser, task.assignee, task.taskPrivileges.canUserClaim])

  const {
    completeTaskWithoutCommentModal,
    setCompleteTaskWithoutCommentModalVisible
  } = useCompleteTaskWithoutCommentModal({ task, checkCompletable })

  const discardCurrentDownloadModal = useMemo(() => {
    return <ConfirmModal
      title={t('discardCurrentDownloadModal.title')}
      confirmButtonLabel={t('discardCurrentDownloadModal.confirmButton')}
      visible={visibleDiscardModal}
      onConfirm={() => {
        apiDeleteAssetLock(task.dataResource.id).catch(() => alert.error(t('discardCurrentDownloadModal.errorMessage')))
        setTaskDiscardPending(task, false)
      }}
      onCancel={() => setTaskDiscardPending(task, false)}
    >
      <TabularData
        headingCol={true}
        data={[
          [
            t('discardCurrentDownloadModal.body'), ''
          ]
        ]}
      />
    </ConfirmModal>
  }, [visibleDiscardModal, task, t, alert])

  useSignal(
    toggleCompleteTaskWithoutCommentModalSignal,
    ({ visible, taskId }) => {
      if (task.id !== taskId) return
      setCompleteTaskWithoutCommentModalVisible(visible)
    }
  )

  return (
    <>
      {completeTaskWithoutCommentModal}
      {discardCurrentDownloadModal}
    </>
  )
}

/**
 * Handelt den Klick auf das Editierungssymbol.
 *
 * @param id Die Id des Assets, welches editiert werden soll.
 * @param webdavUri Die WebdavUri zu den Asset.
 * @param alert AlertManager zum pushen von Alerts ins System.
 */
function handleEditAssetClick (id: EMK, webdavUri: string, setWebdavUri: (value: SetStateAction<string | null>) => void, alert: AlertManager): void {
  apiHasLock(id).then(result => {
    if (result?.text === 'true') {
      alert.error('Das Dokument ist gesperrt')
      return
    }

    if (webdavUri === null) {
      return
    }

    // WebdavUri vorher auf `null` setzen, damit das Setzen der gleichen Uri
    // zweimal hintereinander als Änderung erkannt wird. Ansonsten würde
    // der Browser den Link ab dem zweiten Klick ignorieren und das Dokument
    // nicht versuchen zu öffnen.
    setWebdavUri(null)
    setWebdavUri(webdavUri)
  }).catch(noop)
}

/**
 * Zusätzliche Downlaod requirement Funktion, die den Download Button ein/ausblenden kann.
 * Der Downloadbutton wird bei gesperrten Assets entfernt.
 *
 * @param asset Das Asset zu dem Download Button.
 */
function additionCanUserDownloadRequirement (asset: ApiTaskDataResourceAsset): boolean {
  return !asset.locked
}

/**
 * Zusätzliche Upload requirement Funktion, die den Upload Button ein/ausblenden kann.
 * Der Uploadbutton wird bei offenen Assets entfernt.
 *
 * @param asset Das Asset zu dem Upload Button.
 */
function additionCanUserUploadRequirement (asset: ApiTaskDataResourceAsset): boolean {
  return asset.locked
}

/**
 * Utils mit Funktionen und Komponenten zu Task Asset List Items.
 */
export const ListItemTaskAssetUtils: IListItemTaskAssetUtils = {
  additionalDropDownRenderRequirement,
  getStatusIcons,
  AdditionalDropDownRender,
  AdditionalRootRender,
  handleEditAssetClick,
  additionCanUserDownloadRequirement,
  additionCanUserUploadRequirement
}

interface UseCompleteTaskWithoutCommentModalParams {
  task: ApiTask
  checkCompletable: boolean
}

interface UseCompleteTaskWithoutCommentModalReturn {
  completeTaskWithoutCommentModal: ReactElement
  setCompleteTaskWithoutCommentModalVisible: Dispatch<SetStateAction<boolean>>
}

function useCompleteTaskWithoutCommentModal ({
  task,
  checkCompletable
}: UseCompleteTaskWithoutCommentModalParams): UseCompleteTaskWithoutCommentModalReturn {
  const [isVisible, setIsVisible] = useState(false)
  const { t } = useTranslation()
  const comment = 'Autor: Keine Aktualisierung erforderlich'

  const { currentUser } = useUserStore()

  const title = useMemo(() => {
    return t('completeTaskWithoutCommentModal.title')
  }, [t])

  // Schließt einen Task ab. Falls der Task dem User noch nicht zugeordnet ist
  // und kein anderer den Task als Bearbeiter hat und der Task eine Freigabe für
  // das Abschließen aus dem BO Workflow bekommen hat, dann wird der Task vorm abschließen
  // dem User zugeordnet und sofort abschlossen.
  // Die Tasks werden ohne Comment Modal abgeschlossen.
  const handleConfirm = useCallback(async (): Promise<void> => {
    if (!checkCompletable) return

    if (task.assignee !== currentUser?.username) {
      // Der Nutzer muss den Task zuerst annehmen,
      // bevor er ihn abschließen kann.
      await claimTasks(task.id)
    }

    await completeTaskWithComment(task.id, {
      comment
    }).catch(noop)
  }, [checkCompletable, currentUser, task.assignee, task.id])

  function handleCancel (): void {
    setIsVisible(false)
  }

  const modal = useMemo(() => (
    <ConfirmModal
      title={title}
      confirmButtonLabel={t('completeTaskWithoutCommentModal.confirmButton')}
      visible={isVisible}
      onConfirm={handleConfirm}
      onCancel={handleCancel}
    >
      <TabularData
        headingCol={true}
        data={[
          [
            t('completeTaskWithoutCommentModal.documentLabel'),
            task.dataResource.name
          ],
          [t('completeTaskWithoutCommentModal.commentLabel'), `"${comment}"`]
        ]}
      />
    </ConfirmModal>
  ), [handleConfirm, isVisible, t, task.dataResource.name, title])

  return {
    completeTaskWithoutCommentModal: modal,
    setCompleteTaskWithoutCommentModalVisible: setIsVisible
  }
}
