import { useLocation } from '@reach/router'
import 'flagpack/dist/flagpack.css'
import React, { FC, FormEvent, MouseEvent, useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ZoomFadeDiv } from '../../components/Animations/ZoomFadeDiv'
import { Button } from '../../components/Forms/Button'
import { Input } from '../../components/Forms/Input'
import { ItemSelect } from '../../components/Forms/ItemSelect'
import { RequestErrorMessage } from '../../components/Generic/RequestErrorMessage'
import { CenteredLayer } from '../../components/Layout/CenteredLayer'
import { ChildrenProp } from '../../jsx'
import { RequestStatus } from '../../services/api/generic/types'
import { getStorageValue, setStorageValue } from '../../services/localStorageService'
import { login, LoginViewScreen, setCurrentLoginViewScreen, useUserStore } from '../../stores/userStore'
import { useValuesStartStore } from '../../stores/valuesStartStore'
import { LoadingScreen } from './LoadingScreen'
import { SSOLink, SSOLinkData } from './SSOLink'
import { useCustomSSOLinks } from 'sfportal_views_login/useCustomSSOLinks'
import { useGetSSOLinkDefaults } from './useGetSSOLinkDefaults'

type RequiredProps = Pick<SSOLinkData, 'type' | 'url'>
type OptionalProps = Partial<Pick<SSOLinkData, 'label' | 'color' | 'iconPath'>>
export type PartialSSOLinkData = RequiredProps & OptionalProps

interface Props extends ChildrenProp {
  /** Gibt an ob der Websocket noch beim connect ist. */
  connectingSocket: boolean
}

export const LoginScreen: FC<Props> = ({ connectingSocket }) => {
  const location = useLocation()
  const { t, i18n } = useTranslation()
  const getSSOLinkDefaults = useGetSSOLinkDefaults()

  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const {
    status,
    currentLoginViewScreen
  } = useUserStore()

  const { data: valuesStartData } = useValuesStartStore()

  const defaultSSOLinks = useMemo<SSOLinkData[]>(() => {
    const items = Object.entries(valuesStartData?.ssoProviders ?? {})
    return items.map(([type, url]) => {
      const defaults = getSSOLinkDefaults(type)
      return ({
        type,
        url,
        color: defaults?.color ?? '#000000',
        iconPath: defaults?.icon,
        label: defaults?.label ?? 'Unknown SSO Provider'
      })
    })
  }, [getSSOLinkDefaults, valuesStartData])

  const ssoLinks: SSOLinkData[] = useCustomSSOLinks(defaultSSOLinks)

  const isCurrentScreen = useMemo(
    () => currentLoginViewScreen === LoginViewScreen.login,
    [currentLoginViewScreen]
  )

  const isLoading = useMemo(
    () => status === RequestStatus.pending,
    [status]
  )

  const redirectUri = useMemo(() => {
    const deployContext = process.env.REACT_APP_PUBLIC_URL ?? ''
    const domain = `${location.protocol}//${location.host}`
    return `${domain}${deployContext}`
  }, [location.host, location.protocol])

  const handleSubmit = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      try {
        await login(username, password)
      } catch {
      }
    },
    [password, username]
  )

  const handleForgotPassword = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      setCurrentLoginViewScreen(LoginViewScreen.sendEMail)
    },
    []
  )

  const getLanguage = (): string => {
    let language = getStorageValue('i18nextLng', { parse: false })?.split('-')[0]
    if (language !== 'en' && language !== 'de') {
      language = 'en'
    }
    return language
  }

  const dropdownLanguageItems = {
    de: { label: t('login.languages.german'), icon: 'fp fp-md de' },
    en: { label: t('login.languages.english'), icon: `fp fp-md ${process.env.REACT_APP_FLAG_ENGLISH ?? 'us'}` }
  }

  return <>
    <LoadingScreen
      text={t('login.loading')}
      visible={isCurrentScreen && (isLoading || connectingSocket)}
    />

    <ZoomFadeDiv
      className="login-view__view-item"
      visible={isCurrentScreen && !isLoading}
    >
      <CenteredLayer>
        <form className="login-view__form" onSubmit={handleSubmit}>
          <h1 className="login-view__title">{t('login.heading')}</h1>

          <div className="login-view__inputs">
            <Input
              label={t('login.username')}
              id="username"
              name="username"
              autoComplete="username"
              value={username}
              onInput={setUsername}
              autoFocus={true}
            />
            <Input
              label={t('login.password')}
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              value={password}
              onInput={setPassword}
            />
            <ItemSelect
              label={t('login.language')}
              name={'chooselanguage'}
              items={ dropdownLanguageItems }
              value={ getLanguage() }
              onChange={async tag => {
                await i18n.changeLanguage(tag)
                setStorageValue('i18nextLng', tag)
              }}
            />
          </div>

          <RequestErrorMessage status={status} />

          <div className="login-view__buttons">
            <Button type="submit">{t('login.buttons.login')}</Button>

            <Button
              type="button"
              buttonStyle="secondary"
              onClick={handleForgotPassword}
            >
              {t('login.buttons.forgotPassword')}
            </Button>
          </div>

          {ssoLinks.length > 0 ? (
            <>
              <hr />
              <h2>{t('login.ssoTitle')}</h2>

              <div className="login-view__sso-buttons">
                {ssoLinks.map((link) => (
                  <div key={link.url} className="login-view__sso-link">
                    {/*
                      `link.url` kommt von der API `GET /valuesstart`.
                      Es kann von unserer Seite garantiert werden, dass diese
                      mit `&redirectUri=` o. Ä. endet. Daher ist eine einfache
                      Zusammensetzung der beiden Strings ausreichend.
                    */}
                    <SSOLink {...link} url={link.url + redirectUri} />
                  </div>
                ))}
              </div>
            </>
          ) : null}
        </form>
      </CenteredLayer>
    </ZoomFadeDiv>
  </>
}
