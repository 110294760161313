import { CustomizeTreeList } from '../../../../../views/ProductDetail/productTree/list/useCustomizeTreeList'
import { useCanShowAsset } from './useCanShowAsset'

/**
 * Durch Überschreiben dieser Funktion in Kunden-Projekten können die Baumknoten
 * an die Bedürfnisse des Projekts angepasst werden.
 */
export function useCustomizeTreeList (): CustomizeTreeList {
  const canShowAsset = useCanShowAsset()

  return (items) =>
    items.map((node) => ({
      ...node,
      disabled: !canShowAsset(node)
    }))
}
