import { mdiFileSearchOutline } from '@mdi/js'
import {
  loadDocumentTree,
  useDocumentTreeStore
} from 'nomosportal_stores/documentTreeStore'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { ApiTreeNode } from 'sfportal_services_api/apiSchemas'
import { noop } from 'sfportal_utils/function'
import { ProductTreeSubviewsContext } from 'sfportal_views/ProductDetail/productTree/list/useProductTreeContext'
import { FlatTreeNode } from '../../../../../../components/Tree/Tree'
import { TreeNodeAction } from '../../../../../../components/Tree/TreeNode'
import { useCanShowAsset } from '../useCanShowAsset'
import { documentTreeSubview } from '../useGetProjectProductTreeListSubviews'

interface UseDocumentStructureActionResult {
  getDocumentStructureAction: (
    node: FlatTreeNode<ApiTreeNode>,
  ) => TreeNodeAction
  showDocumentStructureAction: (node: FlatTreeNode<ApiTreeNode>) => boolean
}

export function useDocumentStructureAction (): UseDocumentStructureActionResult {
  const { t } = useTranslation()
  const { setCurrentListSubview } = useContext(ProductTreeSubviewsContext)
  const { currentDocumentId } = useDocumentTreeStore()
  const canShowAsset = useCanShowAsset()

  useEffect(() => {
    setCurrentListSubview(
      currentDocumentId === null ? null : documentTreeSubview
    )
  }, [currentDocumentId, setCurrentListSubview])

  function getDocumentStructureAction (
    node: FlatTreeNode<ApiTreeNode>
  ): TreeNodeAction {
    return {
      label: t('documentStructure.buttons.open'),
      icon: mdiFileSearchOutline,
      enabled: canShowAsset(node),
      action () {
        loadDocumentTree(node.id).catch(noop)
      }
    }
  }

  function showDocumentStructureAction (
    node: FlatTreeNode<ApiTreeNode>
  ): boolean {
    return node.item.dataResource?.contentType === 'file/doc'
  }

  return { getDocumentStructureAction, showDocumentStructureAction }
}
