import React, { ReactElement, ReactNode, useEffect, useMemo, useState } from 'react'
import { ApiProduct, ApiTaskDataResourceAsset } from 'sfportal_services_api/apiSchemas'
import { ListItemGroup } from '../../../components/ListItem/ListItemGroup'
import { apiGetFolderAssets } from '../../services/api/folderApiService'
import { ListItemTask } from '../../../components/ListItem/ListItemTask'
import { mdiDotsHorizontal, mdiDownload, mdiFileDocumentOutline, mdiImageOutline } from '@mdi/js'
import { setCurrentFile, useProductDetailStore } from '../../../stores/productDetailStore'
import { noop } from '../../../utils/function'
import { ButtonDropdown } from '../../../components/Forms/ButtonDropdown'
import { MenuItem } from '../../../components/Menu/MenuItem'
import { getFileUriFromEMK } from '../../../routes'

/**
 * Properties mit einem ApiProduct.
 */
interface Props {
  product: ApiProduct
}

/**
 * ListItems mit Autoreninformationen.
 *
 * @param product Das Produkt.
 * @constructor Function Konstruktor.
 */
export function ListItemInformation ({
  product
}: Props): ReactElement {
  /**
   * Gibt an, ob die Auswahl offen ist.
   */
  const [isOpen, setIsOpen] = useState(false)

  /**
   * Die Assets der Autoreninformationen.
   */
  const [assets, setAssets] = useState<ApiTaskDataResourceAsset[]>([])

  /**
   * Wechselt den State des isOpen Proerpties und toggelt somit die Ordner.
   */
  const toggleOpen = (): void => {
    setIsOpen(!isOpen)
  }

  useEffect(() => {
    apiGetFolderAssets(product.id, 'authorinformation')
      .then((result) => {
        setAssets(result.body as ApiTaskDataResourceAsset[])
      }).catch((error) => {
        console.log(error)
      })
  }, [product.id])

  return (<ListItemGroup
    title={'Unterlagen'}
    isOpen={isOpen}
    onIsOpenChange={toggleOpen}>
    {assets.map((asset, index): ReactNode => {
      return <ListItemInformationAsset key={index} asset={asset}/>
    })}
  </ListItemGroup>)
}

/**
 * Properties eines einzelnen InformationAssets.
 */
interface InformationAssetProps {
  asset: ApiTaskDataResourceAsset
}

function ListItemInformationAsset ({
  asset
}: InformationAssetProps): ReactElement {
  const { currentFile } = useProductDetailStore()

  /**
   * Öffnet die Preview im Preview Container des Portals.
   */
  const handleItemClick = (): void => {
    setCurrentFile(asset).catch(noop)
  }

  /**
   * Der Downloadlink des Assets.
   */
  const downloadLink = useMemo(
    () => getFileUriFromEMK(asset.id, 'download'),
    [asset.id]
  )

  return (<ListItemTask
    listItemType="asset"
    title={asset.name}
    icon={asset.contentType?.startsWith('image') ?? false
      ? mdiImageOutline : mdiFileDocumentOutline}
    onItemClick={handleItemClick}
    current={currentFile?.id === asset.id}
    modifiedBy={asset.modifiedBy ?? 'unbekannt'}
    modifiedDate={asset.modifiedDate ?? 'unbekannt'}
    actions={<>
      {downloadLink !== null ? <ButtonDropdown
        buttonStyle="inline"
        buttonIcon={mdiDotsHorizontal}
        title="Weitere Aktionen"
      >
        <MenuItem
          label="Datei herunterladen"
          icon={mdiDownload}
          action={downloadLink}
        />
      </ButtonDropdown> : null }
    </>}
  />
  )
}
