import { createReactHook, createStore } from '@alinnert/tstate'
import {
  ApiDocumentStructureElement,
  ApiDocumentStructureRoot
} from 'sfportal_services_api/apiSchemas'
import { RequestStatus } from 'sfportal_services_api/generic/types'
import { fetchOfficeDocumentTree } from 'sfportal_services_api/officefilesApiService'
import { handleRequestError } from 'sfportal_stores/allStores'
import { getTreeNodeById } from 'sfportal_stores/productTreeStore'
import { TreeState } from 'sfportal_stores/TreeState'
import { arrayHasItems, arrayWithout } from 'sfportal_utils/array'
import { GenericValue } from 'sfportal_utils/utility-types'
import { toFlatTreeNode } from '../../components/Tree/toFlatTreeNode'
import { FlatTreeNode } from '../../components/Tree/Tree'

// #region store
export interface DocumentTreeStore
  extends TreeState<FlatTreeNode<ApiDocumentStructureElement>> {
  nodes: {
    [id: string]: FlatTreeNode<ApiDocumentStructureElement>
  } & {
    documentRoot?: FlatTreeNode<ApiDocumentStructureRoot>
  }
  relations: Record<
  'root' | FlatTreeNode<ApiDocumentStructureElement>['id'],
  Array<FlatTreeNode<ApiDocumentStructureElement>['id']>
  >
  openNodeIds: Array<FlatTreeNode<ApiDocumentStructureElement>['id']>
  /**
   * ID (nicht EMK) des Baumknotens, dessen Dokumentstruktur angezeigt werden
   * soll.
   */
  currentDocumentId: string | null
  status: RequestStatus
  currentHeadingId: string | null
}

function getInitialState (): DocumentTreeStore {
  return {
    nodes: {},
    relations: {},
    openNodeIds: [],
    currentDocumentId: null,
    status: RequestStatus.ok,
    currentHeadingId: null
  }
}

const store = createStore(getInitialState())

export const useDocumentTreeStore = createReactHook(store)

const mutations = {
  reset (): void {
    store.set(getInitialState())
  },

  setCurrentDocumentId (
    currentDocumentId: DocumentTreeStore['currentDocumentId']
  ): void {
    store.set({ currentDocumentId, status: RequestStatus.ok })
  },

  setTree (
    nodes: DocumentTreeStore['nodes'],
    relations: DocumentTreeStore['relations']
  ) {
    store.set({ nodes, relations })
  },

  setStatus (status: DocumentTreeStore['status']): void {
    store.set({ status })
  },

  toggleNode (id: GenericValue<DocumentTreeStore['openNodeIds']>): void {
    const open = store.state.openNodeIds.includes(id)
    mutations.openNode(id, open)
  },

  openNode (
    id: GenericValue<DocumentTreeStore['openNodeIds']>,
    open: boolean
  ): void {
    if (open) {
      store.set({ openNodeIds: [...store.state.openNodeIds, id] })
    } else {
      store.set({ openNodeIds: arrayWithout(store.state.openNodeIds, id) })
    }
  },

  setCurrentHeadingId (
    currentHeadingId: DocumentTreeStore['currentHeadingId']
  ): void {
    store.set({ currentHeadingId })
  }
}
// #endregion store

// #region actions
export function resetDocumentTreeStore (): void {
  mutations.reset()
}

export async function loadDocumentTree (
  id: DocumentTreeStore['currentDocumentId']
): Promise<void> {
  if (id === null) {
    mutations.reset()
    return
  }

  mutations.setStatus(RequestStatus.pending)

  try {
    const treeNode = getTreeNodeById(id)
    if (treeNode === null) {
      mutations.reset()
      return
    }
    const dataResource = treeNode.item.dataResource
    if (dataResource === null) {
      mutations.reset()
      return
    }

    const result = await fetchOfficeDocumentTree(
      dataResource.id,
      () => store.state.currentDocumentId
    )
    if (result === null) return

    const documentTree = result.body as ApiDocumentStructureRoot
    const { nodes, relations } = parseServerResponse(documentTree)
    mutations.setCurrentDocumentId(id)
    mutations.setTree(nodes, relations)
  } catch (error) {
    mutations.setStatus(handleRequestError(error))
  }
}

export function toggleNodeOpenState (
  id: FlatTreeNode<
  ApiDocumentStructureRoot | ApiDocumentStructureElement
  >['id'],
  open?: boolean
): void {
  if (open !== undefined) {
    mutations.openNode(id, open)
  } else {
    mutations.toggleNode(id)
  }
}

export function setCurrentHeadingId (
  id: DocumentTreeStore['currentHeadingId']
): void {
  mutations.setCurrentHeadingId(id)
}
// #endregion actions

// #region functions
/**
 * Parst den gesamten Baum, der von der API zurückkommt und wandelt ihn in die
 * flache Struktur um, der vom Store gefordert wird.
 */
function parseServerResponse (
  apiRootNode: ApiDocumentStructureRoot
): Pick<DocumentTreeStore, 'nodes' | 'relations'> {
  const nodes: DocumentTreeStore['nodes'] = {}
  const relations: DocumentTreeStore['relations'] = {}

  parseNodes({
    id: 'root',
    currentNodes: apiRootNode,
    level: 0,
    path: []
  })

  interface ParseNodesParams {
    id: ApiDocumentStructureElement['id']
    currentNodes: ApiDocumentStructureElement[] | ApiDocumentStructureRoot
    level: number
    path: string[]
  }
  function parseNodes ({
    id,
    currentNodes,
    level,
    path
  }: ParseNodesParams): void {
    // Wenn es sich um den Root-Knoten handelt
    if (!Array.isArray(currentNodes)) {
      const rootNode = currentNodes

      nodes.documentRoot = {
        id: rootNode.id,
        hasChildren: arrayHasItems(rootNode.children),
        level: 0,
        path: [],
        item: rootNode
      }

      relations.root = [rootNode.id]
      relations.documentRoot = rootNode.children.map((child) => child.id)

      const children = rootNode.children.map((node) =>
        toFlatTreeNode({
          id: node.id,
          item: node,
          level,
          path,
          getChildren: (item) => item.children,
          recursiveCallback: parseNodes
        })
      )

      for (const child of children) {
        nodes[child.id] = child
      }

      // Alle Kinder des Root-Knoten parsen
      for (const child of rootNode.children) {
        parseNodes({
          id: child.id,
          currentNodes: child.children,
          level: level + 1,
          path: [...path, child.id]
        })
      }
      return
    }

    // Wenn es sich NICHT um den Root-Knoten handelt
    const children = currentNodes.map((node) =>
      toFlatTreeNode({
        id: node.id,
        item: node,
        level,
        path,
        getChildren: (item) => item.children,
        recursiveCallback: parseNodes
      })
    )
    relations[id] = []

    for (const child of children) {
      nodes[child.id] = child
      relations[id]?.push(child.id)
    }
  }

  return { nodes, relations }
}
// #endregion functions
