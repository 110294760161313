import { productDetailStoreMutationsAccess, productDetailStoreStateAccess } from 'sfportal_stores/productDetailStore'
import { ApiTask, EMK } from 'sfportal_services_api/apiSchemas'
import { apiPutChangecoauthorreleaseEntityMetaKey } from 'nomosportal_services_api/taskassetsApiService'
import { ApiTaskDataresourceAssetCoAuthor } from 'nomosportal_interfaces/ApiTaskInterfaces'
import { resetDocumentTreeStore } from '../documentTreeStore'

// Erweiterung des productDetailStores aus dem Produkt.

/**
 * Ander die Mitautorenfreigabe eines Assets und updated die States im Store.
 *
 * @param entityMetaKey Der EntityMetakey des Assets.
 */
export async function changeCoAuthorReleaseCustom (
  entityMetaKey: EMK
): Promise<void> {
  await apiPutChangecoauthorreleaseEntityMetaKey(entityMetaKey)
  // Da der State im Store als Readonly nach außen gegeben werden kann, kann an dieser Stelle mit den
  // Tasks im entsprechenden StoreState gearbeitet werden. Readonly dient hier als Sicherheit, dass
  // niemand den State außerhalb der dafür vorgesehenen mutations ändert.
  productDetailStoreMutationsAccess.setTasks(
      Object.fromEntries(Object.entries(productDetailStoreStateAccess.tasks).map(([key, task]) => {
    if (task.dataResource.id === entityMetaKey) {
      (task.dataResource as ApiTaskDataresourceAssetCoAuthor).hasGrantedReleaseForCoAuthor =
        !(task.dataResource as ApiTaskDataresourceAssetCoAuthor).hasGrantedReleaseForCoAuthor
    }
    return [key, task]
  })
  ))
}

export function setTaskDiscardPending (task: ApiTask, discardPending: boolean): void {
  productDetailStoreMutationsAccess.setTaskDiscardPending(task, discardPending)
}

export function resetSubStores (): void {
  resetDocumentTreeStore()
}
