import { Response } from 'superagent'
import { sendRequest } from './superagent'

interface SendLoginParams { username: string, password: string }
interface SendTokenLoginParams { token: string }

export async function sendLogin (params: SendLoginParams): Promise<Response> {
  const { username, password } = params
  const body = { username, password }
  return await sendRequest('post', 'users/login', { body })
}

export async function sendTokenLogin (params: SendTokenLoginParams): Promise<Response> {
  const { token } = params
  const body = { token }
  return await sendRequest('post', 'users/login/token', { body })
}

interface SendResetPasswordParams {
  email: string
}

export async function sendResetPassword (
  params: SendResetPasswordParams
): Promise<Response> {
  const { email } = params
  const body = { email }
  return await sendRequest('post', 'users/password/email', { body })
}

interface SendSetNewPasswordParams {
  token: string
  newPassword: string
}

export async function sendSetNewPassword (
  params: SendSetNewPasswordParams
): Promise<Response> {
  const { token, newPassword } = params
  const body = { token, newPassword }
  return await sendRequest('post', 'users/password/reset', { body })
}

interface SendChangePasswordParams {
  oldPassword: string
  newPassword: string
}

export async function sendChangePassword (
  params: SendChangePasswordParams
): Promise<Response> {
  const { oldPassword, newPassword } = params
  const body = { oldPassword, newPassword }
  return await sendRequest('post', 'users/password', { body })
}
