import { ApiTreeNode } from 'sfportal_services_api/apiSchemas'
import { useUserStore } from 'sfportal_stores/userStore'
import { FlatTreeNode } from '../../../../../components/Tree/Tree'

type CanShowAsset = (node: FlatTreeNode<ApiTreeNode>) => boolean

/**
 * Liefert eine Funktion zurück, mithilfe derer festgestellt werden kann, ob der
 * aktuelle Nutzer die Berechtigung hat, weitere Infos zu einem
 * Produktbaumknoten anzusehen.
 */
export function useCanShowAsset (): CanShowAsset {
  const { currentUser } = useUserStore()

  return (node) => {
    // Wenn kein Benutzer eingeloggt, dann Berechtiung pauschal verweigern.
    if (currentUser === null) {
      return false
    }

    // Wenn es sich nicht um ein Asset handelt, dann Berechtigung pauschal
    // erteilen. Ansonsten können andere Knoten nicht angeklickt werden.
    if (node.item.nodeType !== 'ASSET') {
      return true
    }

    // DataResource holen und bei Assets ohne DataResource (was ein Fehlerfall
    // ist) Berechtigung verweigern.
    const { dataResource } = node.item
    if (dataResource === null) return false

    // Wenn die DataResource vorhanden aber noch nicht mit Daten befüllt ist,
    // Berechtigungen erteilen, da diese erst nachträglich von der API geladen
    // werden und dann erst wirklich abgefragt werden können.
    if (dataResource.name === null) {
      return true
    }

    // Wenn der Nutzer Autor des Dokuments ist, dann Berechtigung erteilen.
    if (
      (dataResource.authors ?? []).some(
        (author) => author.id === currentUser.id
      )
    ) {
      return true
    }

    // Wenn Mitautorenfreigabe erteilt wurde, dann Berechtigung erteilen.
    if (dataResource.hasGrantedReleaseForCoAuthor) {
      return true
    }

    // Ansonsten Berechtigung verweigern.
    return false
  }
}
